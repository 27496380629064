<template>
  <pageBack>
    <div class="logo"></div>
    <div class="from">
      <PaInput
        class="myInput"
        v-model="from.user"
        type="email"
        placeholder="请输入邮箱*"
        is_required_text="请输入邮箱"
        :submitType="submitType"
        :is_required="true"
        :icon="require('@/assets/images/main/icon02.png')"
      />
      <Pacode
        class="myInput"
        v-model="from.code"
        :voucherCode="from.user.value"
        placeholder="请输入验证码*"
        error_content="验证码无效"
        is_required_text="请输入验证码"
        @voucherErr="voucherErr"
        @clickCode="clickCode"
        :submitType="submitType"
        :icon="require('@/assets/images/main/icon03.png')"
      />
      <PaInput
        class="myInput"
        v-model="from.password"
        inputType="password"
        placeholder="请输入密码*"
        error_content="请输入密码"
        is_required_text="请输入密码"
        :submitType="submitType"
        :is_required="true"
        :icon="require('@/assets/images/main/icon01.png')"
      />
      <PaInput
        class="myInput"
        v-model="from.checkPassword"
        :type="`^${from.password.value}$`"
        inputType="password"
        placeholder="请再次确认密码*"
        error_content="两次密码输入不同"
        is_required_text="请再次确认密码"
        :submitType="submitType"
        :is_required="true"
        :icon="require('@/assets/images/main/icon01.png')"
      />
    </div>
    <div class="submitButten" @click="submit">确定</div>
    <div class="auxiliary">
      <div class="toRegister" @click="toLogin">
        <i class="el-icon-arrow-left"></i>返回登录
      </div>
      <div class="toRegister" @click="toRegister">免费注册</div>
    </div>
  </pageBack>
</template>
<script>
import PaInput from "@/components/basic/PaInput.vue";
import Pacode from "@/components/basic/Pacode.vue";
import pageBack from "@/components/basic/pageBack.vue";
import { register } from "@/assets/js/api";
import { handleResponseErrors, showMsg } from "@/assets/js/utils";
export default {
  computed: {},
  data() {
    return {
      from: {
        user: { value: "", isError: false },
        code: { value: "", isError: false },
        password: { value: "", isError: false },
        checkPassword: { value: "", isError: false }
      },
      submitType: false,
      session_id: ""
    };
  },
  components: { PaInput, Pacode, pageBack },
  methods: {
    submit() {
      this.submitType = true;
      this.$nextTick(async () => {
        if (Object.values(this.from).some(item => item.isError)) {
          return;
        }
        try {
          await this.sendForm();
          showMsg("修改成功");
          this.toLogin();
        } catch (error) {
          if (error.toString().match(/auth_failed/)) {
            return;
          }
          console.log(error);
        }
      });
    },
    voucherErr() {
      this.from.user.isError = true;
    },
    async clickCode() {
      let data = {
        email: this.from.user.value,
        verify_type: "reset_password"
      };
      try {
        let ref = await register.getCode(data);
        if (ref.code != 0) {
          handleResponseErrors(ref.code, ref.msg);
          return;
        }
        this.session_id = ref.session_id;
      } catch (error) {
        console.log(error);
      }
    },
    async sendForm() {
      let data = {
        session_id: this.session_id,
        email: this.from.user.value,
        verify_code: this.from.code.value,
        password: this.from.password.value
      };
      let res = await register.resetUser(data);
      if (res.code != 0) {
        handleResponseErrors(res.code, res.msg);
        throw new Error("auth_failed");
      }
      return res;
    },
    toRegister() {
      this.$router.push({
        path: "/register"
      });
    },
    toLogin() {
      this.$router.push({
        path: "/login"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.logo {
  width: 166px;
  height: 121px;
  margin: 90px auto 0;
  background-image: url("../../assets/images/main/logo.png");
  background-size: 100% 100%;
}
.from {
  width: 570px;
  margin: 0 auto;
  padding: 40px 0 0;
  box-sizing: border-box;
  .myInput {
    background: #ffffff;
    margin-top: 20px;
  }
}
.submitButten {
  margin: 30px auto 0;
  width: 570px;
  height: 56px;
  background: $green;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  line-height: 56px;
  text-align: center;
}
.auxiliary {
  width: 570px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 100px;
  .toRegister {
    color: $green;
    cursor: pointer;
  }
  .toReset {
    color: $fontSize-gray;
  }
}
</style>
